import responsiveIframe from './_components/responsive-iframe';

function initEvents() {
    const menuBtn = document.querySelector('.menu-btn.open');
    menuBtn.addEventListener('click', function() {
        document.body.classList.add('menu-open')
    });
    responsiveIframe.iframeListener();
}

function bootstrap() {
    initEvents();
}
document.addEventListener('DOMContentLoaded', bootstrap, false);
